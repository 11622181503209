
import { defineComponent, onMounted, ref, watch } from "vue";
import { setModal } from "@/core/helpers/config";
import { supabase } from "@/service/SupabaseService";
import { definitions } from "@/types/supabase";
import { useRoute } from "vue-router";
import Avatar from "../shared/Avatar.vue";
export default defineComponent({
  name: "platform",
  components: { Avatar },
  setup() {
    const handleChangeStateModal = () => {
      setModal("KTCreateNewTicket");
    };
    const route = useRoute();

    const platformId = route.params["id"] as string;

    const admin = ref({
      firstName: "Karina",
      lastName: "Clark",
      description: "Admin | Administrator",
    });
    const platform = ref();

    watch(
      () => route.params.id,
      async (newId) => {
        getPlatform(newId);
      }
    );

    const getPlatform = (id) => {
      supabase

        .from<definitions["platforms"]>("platforms")

        .select(
          `
        name,releaseDate,
        users (firstName,lastName),
        projects(*),
        ticketsPrefix
      `
        )
        .eq("id", id)
        .single()
        .then(({ data }) => {
          platform.value = data;
        });
    };

    onMounted(() => {
      getPlatform(platformId);
    });

    return {
      admin,
      handleChangeStateModal,
      platform,
    };
  },
});
